<template>
    <div>
        <div class="top">
            <div class="topleft">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/home' }">{{$t('home')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{$t('xtgl')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{$t('jegl')}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="topright">
                <el-link type="primary" @click="gotoadd">+{{$t('addrole')}}</el-link>
            </div>
        </div>
        <div class="cont" v-loading="loading">
            <div class="listbox">
                    <div class="listleft">
                        <el-table
                            @row-click="clickitem"
                            :data="list"
                            :header-cell-style="{'background':'#f5f5f5','font-weight':'bold','color':'#555555','padding':'6px 0'}"
                            stripe
                            border
                            :highlight-current-row='true'
                            style="width: 100%">
                            <el-table-column
                            prop="id"
                            align="center"
                            label="ID"
                            width="40">
                            </el-table-column>
                            <el-table-column
                            prop="name"
                            :label="$t('rolename')"
                            >
                            </el-table-column>
                            <el-table-column
                            prop="dataScope"
                            :label="$t('data')"
                            >
                            </el-table-column>
                            <el-table-column
                            prop="level"
                            :label="$t('roleLe')"
                            >
                            </el-table-column>
                            <el-table-column
                            prop="level"
                            :label="$t('createTime')"
                            >
                                <template slot-scope="scope">
                                    {{scope.row.createTime|changedate}}
                                </template>
                            </el-table-column>
                            <el-table-column
                            prop="jobName"
                            :label="$t('operator')"
                            width="150">
                                <template slot-scope="scope">
                                    <el-button size="small"  @click="openedit(scope.row)" type="primary" icon="el-icon-edit" circle plain style="margin-right:4px;"></el-button>
                                    <!-- <el-popconfirm
                                    title="确定要删除吗？"
                                    @confirm="deleterole(scope.row)"
                                    >
                                    <el-button size="small"   type="warning" icon="el-icon-delete-solid" circle slot="reference" plain></el-button>
                                    </el-popconfirm> -->
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="listright">
                        <div class="listrighttop">
                            <strong>{{$t('fpcd')}}</strong>
                            <span><el-button type="warning" size="mini" @click="savemenu">{{$t('baoc')}}</el-button></span>
                        </div>
                        <div>
                            <el-tree
                            :data="menulist"
                            show-checkbox
                            :check-strictly="false"
                            node-key="id"
                            ref="tree"
                            @check="getChecked"
                            :props="defaultProps">
                            </el-tree>
                        </div>
                    </div>
            </div>

        </div>
    </div>
</template>
<script>
import apiurl from "@/api/sys"
import _api from "@/api/index"
export default {
    data(){
        return{
            defaultProps: {
                children: 'children',
                label: 'label',
                isLeaf: 'isLeaf'
            },
            list:[],
            pageNo:1,
            pageSize:10,
            total:0,
            loading:false,
            menulist:[],
            ids:[],
            loading:false,
            clickall:false,
            setid:""
        }
    },
    created(){
        this.getList()
        this.getmenu()
    },
    methods:{
        deleterole(row){
            _api.delete(apiurl.roles,row.id).then(res=>{
                console.log(res)
                // this.$msg.success("删除成功！")
                this.$notify({
                    title: this.$t('success'),
                    message: this.$t('delSuccess'),
                    type: 'success'
                });
                this.getList()
            }).catch(err=>{
                this.$notify.error({
                    title: this.$t('error'),
                    message: err.data.message
                });
            })
        },
        savemenu(){
           // console.log(this.ids)
            var idsobj=[]
            for(let iditem of this.ids){
                idsobj.push({id:iditem})
            }
            console.log(idsobj)
            _api.put(apiurl.menu,{id:this.setid,menus:idsobj}).then(res=>{
                // console.log(res)
                this.$notify.success({
                    title: this.$t('success'),
                    message: this.$t('bccdcg'),
                })
            }).catch(err=>{
                this.$notify.error({
                    title:this.$t('error'),
                    message:err.data.message
                })
            })
        },
        getChecked(obj){//点击树前面的选择框时执行方法

            // var wz=this.ids.indexOf(obj.id)

            // if(wz<0){
            //     this.ids.push(obj.id)
            // }else{
            //     this.ids.splice(wz,1)
            // }

            let aa=this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys())
            // console.log(aa)
           // this.ids=aa
           this.ids=aa
           aa.forEach((i,n) => {
            var node = this.$refs.tree.getNode(i);
            if(node.isLeaf){
                this.$refs.tree.setChecked(node, true);
            }
           })
 
        },
        loadchild(node, resolve){
            _api.get(apiurl.perm,{pid:node.data.id}).then(res=>{
                var zsarr=res.content
                for(let item of zsarr){
                    item.isLeaf=!item.hasChildren
                }
                resolve(zsarr)
            }).catch(err=>{
                //this.$msg.error(err.data.message)
                this.$notify.error({
                    title: this.$t('error'),
                    message: err.data.message
                });
            })
        }, 
        getmenu(){
            _api.get(apiurl.perm,{}).then(res=>{
                console.log(res)
                this.menulist=res.content
            })
        },
        getList(){
            this.loading=true
            _api.get(apiurl.roleslist,{}).then(res=>{
                this.loading=false
                //console.log(res)
                this.list=res
            })
        },
        clickitem(e){//点击了每一行
           // console.log(e)
           this.clickall=true
            this.$refs.tree.setCheckedKeys([])
            //this.$refs.tree.setCheckedNodes()
            this.setid=e.id
            _api.get(apiurl.roles,e.id).then(res=>{
                //console.log(res)
                var zsmenu=[]
                for(let item of res.menus){
                    zsmenu.push(item.id)
                }
                this.ids=zsmenu
                console.log(this.ids)
                console.log(34)
                this.ids.forEach((i,n) => {
                    var node = this.$refs.tree.getNode(i);
                    if(node.isLeaf){
                        this.$refs.tree.setChecked(node, true);
                    }
                })
            })
            this.clickall=false


        },
        handchange(data){//分页Size变化
            this.pageSize=data
            this.pageNo=1
            this.getList()
        },
        currentchange(data){//当前页变化
            console.log(data)
            this.pageNo=data
            this.getList()
        },
        gotoadd(){//跳转到添加页面
            this.$router.push("/role/add")
        },
        openedit(row){
            this.$router.push({path:"/role/edit",query:{id:row.id}})
        }
    }
}
</script>
<style scoped>
.listbox{ display: flex;}
.listleft{ width: 78%;}
.listright{ width: 21%; margin-left: 1%;border: 1px solid #EBEEF5; }
.listrighttop{ padding:0 10px;background: rgb(245, 245, 245); height: 40px; overflow: hidden; line-height: 40px;border-bottom: 1px solid #EBEEF5;}
.listrighttop strong{ float: left;}
.listrighttop span{ float: right;}
</style>